import React from 'react';
import {
  Button,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Upload,
} from 'antd';
import {
  DeleteOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import styled from 'styled-components';

import ImagePlaceholder from '../../assets/images/preview_image.jpeg';
import { FormLabel } from '../../components/common/styledComponents';
import { isValidHttpUrl } from '../../util';

type Props = {
  columnPerPage: number;
  coverImageSrc?: string;
  footerText?: string;
  headerText?: string;
  onClose: Function;
  setColumnPerPage?: Function;
  setCoverImageSrc?: Function;
  setFooterText?: Function;
  setHeaderText?: Function;
  visible: boolean;
};

const DialogBody = styled.div`
  width: 600px;

  & .col-radio-area {
    margin: 6px 12px 12px;
  }

  & .cover-image-box {
    border: 1px solid #D9D9d9;
    border-radius: 10px;
    height: 130px;
    margin-top: 12px;
    width: 130px;
  }

  & .cover-image-box .ant-image {
    height: 100%;
    width: 100%;
  }

  & .cover-image-box .ant-image {
    height: 100%;
    width: 100%;
  }

  & .cover-image-box .ant-image-img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }

  & .cover-image-tool-btn {
    padding: 2px 8px;
  }

  & .cover-image-toolbox-col {
    margin: 12px 6px;
  }

  & .cover-image-header {
    margin: 12px 0;
  }

  & .form-label {
    font-size: 16px;
  }

  & .image-url-input {
    height: 131px;
  }
`;

const PrintSettingDialog = (props: Props) => {
  const { useState } = React;
  const [columnPerPage, setColumnPerPage] = useState(props.columnPerPage);
  const [coverImageSourceType, setCoverImageSourceType] = useState(isValidHttpUrl(props.coverImageSrc) ? 'remote' : 'local');
  const [coverImageSrc, setCoverImageSrc] = useState(props.coverImageSrc || '');
  const [coverImageInputVersion, setCoverImageInputVersion] = useState(0);
  const [footerText, setFooterText] = useState(props.footerText || '');
  const [headerText, setHeaderText] = useState(props.headerText || '');

  const defaultCoverImageSourceType = () => {
    const typ = isValidHttpUrl(props.coverImageSrc) ? 'remote' : 'local';

    //setCoverImageSourceType(typ);

    return typ;
  };

  const dropCoverImage = () => {
    setCoverImageSrc('');
    setCoverImageInputVersion(coverImageInputVersion + 1);
  };

  const onCancelDialog = () => {
    props.onClose();
  };

  const onColumnPerPageChange = (evt: any) => {
    setColumnPerPage(evt.target.value / 2);
  };

  const onCoverImageSourceTypeChange = (evt: any) => {
    //console.log('evt', evt.target.value);
    setCoverImageSourceType(evt.target.value);
  };

  const onCoverImageUrlChange = (evt: any) => {
    //console.log('ch', evt.target.value);
    //if (props.setFooterText) props.setFooterText(evt.target.value.trim());
    setCoverImageSrc(evt.target.value.trim());
  };

  const onFileStateChange = (evt: any) => {
    const reader = new FileReader();

    //console.log('evt', evt);
    reader.addEventListener('load', () => {
      //console.log(reader.result);
      setCoverImageSrc(reader.result as string);
    }, false);
    reader.readAsDataURL(evt.file);
  };

  const onFooterChange = (evt: any) => {
    //console.log('ch', evt.target.value);
    //if (props.setFooterText) props.setFooterText(evt.target.value.trim());
    setFooterText(evt.target.value.trim());
  };

  const onHeaderChange = (evt: any) => {
    //console.log('ch', evt);
    //if (props.setHeaderText) props.setHeaderText(evt.target.value.trim());
    setHeaderText(evt.target.value.trim());
  };

  const onSave = () => {
    if (props.setColumnPerPage) props.setColumnPerPage(columnPerPage);
    if (props.setCoverImageSrc) props.setCoverImageSrc(coverImageSrc);
    if (props.setFooterText) props.setFooterText(footerText);
    if (props.setHeaderText) props.setHeaderText(headerText);

    console.log('cc', columnPerPage);
    props.onClose();
  };

  return (<>
    <Modal
      centered
      className=""
      closable={false}
      //closeButton
      //footer={dialogFooter()}
      //fullscreen
      //okText="Save"
      onCancel={onCancelDialog}
      //onFullscreen={onFullscreen}
      onOk={onSave}
      style={{ paddingBottom: 0 }}
      title={'Print Setting'}
      visible={props.visible}
      width={'auto'}
    >
      <DialogBody>
        <div>
          <div>
            <FormLabel className="form-label">Products per page</FormLabel>
          </div>
          <div className="col-radio-area">
            <Radio.Group
              //buttonStyle="solid"
              defaultValue={columnPerPage * 2}
              onChange={onColumnPerPageChange}
              //optionType="button"
              options={[
                {label: '4 Products', value: 4},
                {label: '6 Products', value: 6},
                {label: '8 Products', value: 8},
                {label: '12 Products', value: 12},
                {label: '16 Products', value: 16},
              ]}
            />
          </div>
        </div>
        <div>
          <FormLabel className="form-label">Header</FormLabel>
          <Input
            allowClear
            defaultValue={headerText}
            onChange={onHeaderChange}
            size="large"
          />
        </div>
        <div>
          <FormLabel className="form-label">Footer</FormLabel>
          <Input
            allowClear
            defaultValue={footerText}
            onChange={onFooterChange}
            size="large"
          />
        </div>
        <div>
          <Row align="middle" className="cover-image-header" justify="space-between">
            <FormLabel className="form-label">Cover Image URL</FormLabel>
            <Radio.Group
              buttonStyle="solid"
              defaultValue={defaultCoverImageSourceType()}
              onChange={onCoverImageSourceTypeChange}
              optionType="button"
              options={[
                {label: 'Local', value: 'local'},
                {label: 'Remote', value: 'remote'},
              ]}
            >
            </Radio.Group>
          </Row>
          {coverImageSourceType === 'local' && (
            <Upload.Dragger
              accept="image/*"
              beforeUpload={() => false}
              itemRender={() => null}
              onChange={onFileStateChange}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag cover picture to this area</p>
            </Upload.Dragger>
          )}
          {coverImageSourceType === 'remote' && (
            <Input.TextArea
              allowClear
              className="image-url-input"
              defaultValue={isValidHttpUrl(coverImageSrc) ? coverImageSrc : ''}
              key={`cover-img-src-input${coverImageInputVersion}`}
              onChange={onCoverImageUrlChange}
              placeholder="Please input image url here"
              size="large"
            />
          )}
          {coverImageSrc && (
            <Row>
              <div className="cover-image-box">
                <Image
                  fallback={ImagePlaceholder}
                  src={coverImageSrc}
                />
              </div>
              <div className="cover-image-toolbox-col">
                <Button
                  className="cover-image-tool-btn"
                  onClick={dropCoverImage}
                >
                  <DeleteOutlined />
                </Button>
              </div>
            </Row>
          )}
        </div>
      </DialogBody>
    </Modal>
  </>);
};

export default PrintSettingDialog;
