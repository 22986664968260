import { Modal, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Loop from './assets/icons/loop';
import './assets/styles/app.less';
import BannerToken from './components/BannerToken';
import IddleModal from './components/IddleModal';
import Navigation from './components/Navigation';
import {
  APP_VERSION,
  LSK_PROFILE_IS_SELECTED,
  LSK_PROFILE_SELECTED_INDEX,
  LS_THEME_CODE,
} from './constants/config';
import _notificationActions from './redux/actions/notifications';
import _profileActions from './redux/actions/profiles';
import _profileSettingsActions from './redux/actions/profileSettings';
import {
  fetchProfiles,
  fetchProfilesByNetworkNum,
  fetchUserPermissions,
} from './services/userManagement';
import { fetchSiteConfig, logout, setPageTitle } from './util';
import { fetchProfileSettings } from './services/channels';
import { logEnv } from '.';

declare global {
  var __EC_VERSION__: String;
  var __ENV__: Function;
}

const theme = localStorage.getItem(LS_THEME_CODE) || '';
if (theme === 'new') require('./assets/styles/newTheme.less');

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

/**
 * Render-less component that will handle the notification publishing.
 *
 * Mainly to prevent the whole app re-render.
 * TODO: move this to another place?
 *
 * @constructor
 */
const NotificationHandler: React.FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state: any) => state.notifications);
  const notificationActions = bindActionCreators(_notificationActions, dispatch);

  useEffect(() => {
    const {notificationType, message} = notifications;
    if (notificationType === null || message === null || message === undefined) {
      return;
    }

    let duration = (message.length / 120) + Number(process.env.NOTIFICATION_TIMER_SUCCESS);
    if (notificationType === 'error') {
      duration = Number(process.env.NOTIFICATION_TIMER_ERROR) * 60;
    }
    const body = {
      message,
      onClose: () => {
        notificationActions.clearNotification();
      },
      duration,
    };

    switch (notificationType) {
      case 'success':
        notification.success(body);
        break;
      case 'error':
        notification.error(body);
        break;
      default:
        break;
    }

  }, [notifications, notificationActions]);

  return null;
};

const App: React.FC = () => {
  const dispatch = useDispatch();
  const profiles = useSelector((state: any) => state.profiles);

  const actions = bindActionCreators(_profileActions, dispatch);
  const profileSettingsActions = bindActionCreators(_profileSettingsActions, dispatch);
  const [inited, setInited] = useState(false);
  const [loading, setLoading] = useState(true);
  const refIdle = useRef(null);
  const [visible, setVisible] = useState(false);

  const fetchData = async () => {
    try {
      //console.log('nn', process.env.NETWORK_NUM);
      /*if (process.env.NETWORK_NUM) {
        const ps = await fetchProfilesByNetworkNum(process.env.NETWORK_NUM);
        console.log('profiles', ps);
      }
      // use a fn to fetch the profiles according to the nn
      const response = await fetchProfiles();*/
      const response = await getProfiles();
      actions.setProfiles(response);

      if (!profiles.isProfileSelected && Array.isArray(response)) {
        if (response.length === 1) {
          actions.setSelectedIndex(0);
          actions.setIsProfileSelected(true);
        } else if (response.length > 1) {
          const isSelected = parseInt(localStorage.getItem(LSK_PROFILE_IS_SELECTED) || '0');
          const idx = parseInt(localStorage.getItem(LSK_PROFILE_SELECTED_INDEX) || '0');

          //console.log(isSelected, idx, '<-');
          if (isSelected && idx >= 0 && idx < response.length) {
            actions.setSelectedIndex(idx);
            actions.setIsProfileSelected(true);

            /*const profileSettings = await fetchProfileSettings();
            if (Array.isArray(profileSettings)) {
              profileSettingsActions.setProfileSettings(profileSettings);
            }*/
          }
        }
      }

      const profileSettings = await fetchProfileSettings();

      if (Array.isArray(profileSettings)) {
        profileSettingsActions.setProfileSettings(profileSettings);
      }

      const permissions = await fetchUserPermissions(response[0].Email);
      actions.setPermissions(permissions);
      const siteCfg = fetchSiteConfig();
      setPageTitle(siteCfg);
      setLoading(false);
    } catch (e) {
      actions.setProfiles([]);
      actions.setPermissions([]);
      setLoading(false);
      throw e;
    }
  };

  const getProfiles = async () => {
    let ps: any[] = [];

    if (process.env.NETWORK_NUM) {
      ps = await fetchProfilesByNetworkNum(process.env.NETWORK_NUM);
    } else {
      ps = await fetchProfiles();
    }

    return ps;
  };

  const useFetch = () => {
    useEffect(() => {
      fetchData().catch(() => {
      });
    }, []);
  };

  useFetch();


  const onIdle = () => {
    setVisible(true);
  };

  const performLogout = () => {
    logout();
  };

  useEffect(() => {
    if (!inited) {
      globalThis.__EC_VERSION__ = APP_VERSION;
      globalThis.__ENV__ = () => { logEnv(); };
      setInited(true);
    }
  }, [inited]);

  return (
    <>
      <NotificationHandler />
      <BannerToken />
      <Modal
        title="Are you still there?"
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => logout()}
        cancelText='Log Out Now'
        okText='Stay Logged In'
      >
        {
          visible && (
            <IddleModal
              performLogout={() => performLogout()}
            />
          )
        }
      </Modal>
      <IdleTimer
        ref={refIdle}
        element={document}
        onIdle={() => onIdle()}
        debounce={250}
        timeout={1000 * 60 * (Number(process.env.IDLE_TIMEOUT) || 20)}
      />
      {
        loading ? (
          <LoadingWrapper>
            <span data-type="loading-icon">
              <Loop
                height={40}
                width={40}
                style={{
                  transform: 'rotate(45deg)',
                  animation: 'antRotate 1.2s infinite reverse linear',
                }}
              />
            </span>
          </LoadingWrapper>
        ) : <Navigation isProfileSelected={profiles.isProfileSelected} />
      }
    </>
  );
};

export default App;
