import { cwAPI, digitBridgeApi, digitBridgeSimpleApi } from './http';
import { RcFile } from 'antd/es/upload';

interface RelationshipItem {
  CopywritingAttributeRelationshipNum: number,
  CopywritingElementNum: number,
  AttributeNum: number,
  DisplaySeq: number;
}

interface AddCopywritingParams {
  // CopywritingElementNum: number,
  ElementName: string,
  ElementType: number,
  ElementDataType: number,
  Tag: string,
  Pattern: string,
  // DefaultValue: string,
  OptionList: string,
  Note: string,
  DisplaySeq: number,
  Relationships: RelationshipItem[];
  ContentSourceGroup: number;
  DisplayInSearch: number;
}

interface EditCopywritingParams {
  ElementName: string,
  ElementType: number,
  ElementDataType: number,
  Tag: string,
  Pattern: string,
  // DefaultValue: string,
  OptionList: string,
  Note: string,
  DisplaySeq: number;
  ContentSourceGroup: number;
  DisplayInSearch: number;
}

interface SaveCopywritingAttributeItem {
  AttributeNum: number,
  DisplaySeq: number;
}

interface SaveBulkCopywritingelementsParams {
  TypeNum?: number,
  Type?: string,
  ProductFilter?: any,
  Products: any[],
  Items: any[],
  Overwrite: boolean,
  IgnoreSource: boolean,
}

interface UploadCopywritingParams {
  data: any;
  operation: string;
}

interface ImportCopywritingParams {
  MasterAccountNum: number,
  ProfileNum: number,
  Operation: number,
  OriginalFileName: string,
  SystemFileName: string,
  JobGUID: string,
  UserEmail: string,
  FileSize: number,
  type:string,
  DestinationNotEmptyOverwrite: boolean,
  ChildrenNotEmptyOverwrite: boolean,
  ChildrenEmptyOverwrite: boolean,
  SourceEmptyIgnoreUpdate: boolean,
  MappingList: {
    HeaderName: string,
    CopywritingElementNum: number,
    Ignore: boolean,
    RemoveHtmlTags: boolean,
    RemoveNewLine: boolean,
    TrimText: boolean;
  }[],
  RowsCount: number,
  OtherInfo: string,
}

interface AddContentSourceTypeParam {
  EnumName: string;
  Description: string;
}

// get content resources
export const fetchCopywritingelements = async (): Promise<any> => cwAPI.get('/copywritingelements');

// get single content resource
export const getContentResource = async (copywritingElementNum: string): Promise<any> => cwAPI.get(`/copywritingelements/${copywritingElementNum}`);

// content resource add
export const addCopywritingElement = async (params: AddCopywritingParams): Promise<any> => {
  const json = JSON.stringify(params);
  return cwAPI.post('/copywritingelements', json);
};

export const bulkSaveContentResource = async (
  items: StringKAnyVPair[],
  params: StringKAnyVPair,
): Promise<any> => {
  return cwAPI.post('/products/contentresources', items, { params });
};

// content resource edit
export const editCopywritingElement = async (copywritingElementNum: number, params: EditCopywritingParams): Promise<any> => {
  const json = JSON.stringify(params);
  return cwAPI.patch(`/copywritingelements/${copywritingElementNum}`, json);
};

// content resource delete
export const deleteCopywritingElement = async (copywritingElementNum: number, elementName?: string): Promise<any> => cwAPI.delete(`/copywritingelements/${copywritingElementNum}`, elementName ? { params: { name: elementName } } : {});

export const fetchCopywritingAttribute = async (copywritingElementNum: number): Promise<any> => cwAPI.get(`/copywritingelements/${copywritingElementNum}/attributes`);

export const fetchProductCopywritingElements = async (productId: string): Promise<any> => cwAPI.get(`/products/${productId}/copywritingelements`);

export const fetchProductDestinationAttributes = async (productId: string): Promise<any> => digitBridgeApi.get(`/v1/copywriting/${productId}/destinationAttributes`);

export const fetchPullProductDestinationAttributes = async (productId: string): Promise<any> => digitBridgeApi.get(`/v1/copywriting/${productId}/pulldestinationAttributes`);

export const fetchViewList = async (): Promise<any> => {
  const { code, data } = await cwAPI.get('/contentresource/view/all') as StringKAnyVPair;

  if (code === 200 && Array.isArray(data)) {
    return data;
  }
};

export const saveCopywritingAttribute = async (copywritingElementNum: number, params: SaveCopywritingAttributeItem[]): Promise<any> => {
  const json = JSON.stringify(params);
  return cwAPI.post(`/copywritingelements/${copywritingElementNum}/relationship`, json);
};

export const saveCopywritingElements = async (productId: string, params: StringKAnyVPair[]): Promise<any> => {
  const json = JSON.stringify(params);

  return cwAPI.post(`/products/${productId}/copywritingelements`, json);
};

export const saveCopywritingBulkElements = async (
  productId: string,
  sku: string,
  overwrite: boolean,
  params: StringKAnyVPair[],
): Promise<any> => {
  const json = JSON.stringify(params);

  return cwAPI.post(`/products/${productId}/copywritingelements`, json, { params: { sku, overwrite } });
};

export const saveCopywritingSingleElement = async (productId: string, sku: string, eleNum: number, params: StringKAnyVPair): Promise<any> => {
  const overwrite = params.overwrite || false;
  const json = JSON.stringify({ ...params, overwrite });

  return cwAPI.post(`/products/${productId}/copywritingelements/${eleNum}`, json, { params: { sku, overwrite } });
};

export const saveCopywritingElementsPull = async (productId: string, jsonData: StringKAnyVPair[], sku: string, group: string): Promise<any> => {
  const json = JSON.stringify(jsonData);
  const params = { sku, group };

  return cwAPI.post(`/products/${productId}/copywritingelements`, json, { params });
};

export const saveProductDestinationAttributes = async (productId: string, params: StringKAnyVPair[]): Promise<any> => {
  const json = JSON.stringify(params);

  return digitBridgeApi.post(`/v1/copywriting/${productId}/destinationAttributes`, json);
};

export const getAllAttributes = async (): Promise<any> => cwAPI.get('/copywritingelements/relationships');

export const saveBulkPullCopywritingelements = async (params: SaveBulkCopywritingelementsParams): Promise<any> => {
  const json = JSON.stringify(params);

  return cwAPI.post('/products/pull/copywritingelements', json);
};

export const saveBulkPushCopywritingelements = async (params: StringKAnyVPair): Promise<any> => {
  const json = JSON.stringify(params);

  return cwAPI.post('/products/push/copywritingelements', json);
};

export const saveBulkCopyCopywritingelements = async (params: StringKAnyVPair): Promise<any> => {
  const json = JSON.stringify(params);

  return cwAPI.post('/products/copy/copywritingelements', json);
};

export const uploadCopywriting = async (params: UploadCopywritingParams): Promise<any> => {
  const { data, operation } = params;
  return cwAPI.post('/copywritingelements/upload', data, { params: { operation } });
};

export const importCopywriting = async (params: ImportCopywritingParams): Promise<any> => {
  const json = JSON.stringify(params);
  return cwAPI.post('/copywritingelements/import', json);
};


export const fetchContentSourceType = async (): Promise<any> => cwAPI.get('/contentSourceType');


export const addContentSourceType = async (params: AddContentSourceTypeParam): Promise<any> => {
  const json = JSON.stringify(params);
  return cwAPI.post('/contentSourceType/add', json);
};

export const deleteContentSourceType = async (rowNum: number): Promise<any> => {
  return cwAPI.delete(`/contentSourceType/${rowNum}`);
};

export const editContentSourceType = async (rowNum: number, params: AddContentSourceTypeParam): Promise<any> => {
  const json = JSON.stringify(params);
  return cwAPI.post(`/contentSourceType/${rowNum}`, json);
};

export const sortContentSourceType = async (record: any, ascend: boolean): Promise<any> => {
  const { rowNum } = record;
  return cwAPI.post(`/contentSourceType/${rowNum}/sort`, record, { params: { ascend } });
};

export const uploadContentSource = async (file: File | RcFile): Promise<any> => {
  const data = new FormData();
  data.append('file', file);
  return cwAPI.post('/contentresource/import', data);
};

export const getAllContentResources = async (): Promise<any> => cwAPI.get('/copywritingelements/all');

export const getControlTowerOrigin = async (): Promise<any> => digitBridgeApi.get('/v1/controlTowerElements/origin');


export const editAttributeContentResouce = async (attributeNum: number, contentResourceNum: number ): Promise<any> =>{
  const json = JSON.stringify({
    attributeNum,
    contentResourceNum,
  });
  return cwAPI.post('/products/attribute/contentresource', json);
}; 


export const uploadContentSource2 = async (file: File | RcFile): Promise<any> => {
  const data = new FormData();
  data.append('file', file);
  return cwAPI.post('/contentResource/autoMappingToChannelAttributeByFile', data);
};

export const getContentResourceTemplate = async (): Promise<any> => {
  const url = '/v1/files/downloadContentResourceMappingTemplateByProfileNum';
  return digitBridgeSimpleApi.get(url, {
    headers: {
      'Content-Disposition': 'attachment; filename=template.xlsx',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  },)

};