import { digitBridgeApi } from './http';

export const getAvailableMappingsByChannelNum = async (channelNum: number): Promise<Entities.MappingContentProfile[]> => digitBridgeApi.get(`/v1/channelIntegration/${channelNum}/availableMappingContent`);

export const copyMappingTemplate = async (productMappingNum: number, templateName:string, channelAccountNum: number): Promise<any> => {
  const json = JSON.stringify({templateName})
  return digitBridgeApi.post(`/v1/channels/${productMappingNum}/copyTemplate`, json, {headers:{channelAccountNum}})
}

export const mappingFromExistTemplate = async (sourceMappingNum: number, targetMappingNum: number, channelAccountNum: number): Promise<any> => {
  return digitBridgeApi.post(`/v1/channels/copyTemplateRule/${sourceMappingNum}/${targetMappingNum}`, '', {headers: {channelAccountNum}})
}