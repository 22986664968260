import {
  ApiOutlined,
  TagOutlined,
  RetweetOutlined,
  UserOutlined,
  FileDoneOutlined,
  RiseOutlined,
  // ProfileOutlined,
  CarryOutOutlined,
  MonitorOutlined,
  BulbOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import React from 'react';
import DropShippingIcon from '../../assets/icons/dropShippingIcon';
import Export from '../../assets/icons/export';
// import HomeSVG from '../../assets/icons/homeIcon';
import AccountsPayableIcon from '../../assets/icons/accountsPayable';
import AccountsReceivableIcon from '../../assets/icons/accountsReceivable';
import DashboardSVG from '../../assets/icons/dashboardMenu';
import Import from '../../assets/icons/import';
import OfferSVG from '../../assets/icons/localOfferIcon';
import Profile from '../../assets/icons/profile';
import Settings from '../../assets/icons/settings';
import Shipping from '../../assets/icons/shipping';
import Shopping from '../../assets/icons/shoppingIcon';
import CustomService from '../../assets/icons/textSms';
import Warehouse from '../../assets/icons/warehouse';
//import linkERP from '../../util/linkERP';
// import Fan from '../../assets/icons/fan';
// import Eyeglasses from '../../assets/icons/eye_glasses';
import Kanban from '../../assets/icons/kanban';
import { Permissions } from '../../constants/enums/permissions';

const iconColor = 'currentColor';

export type IMenuItem = {
  key: string;
  name?: string;
  route?: string;
  /**
   * If the entry should be a link to an external page
   */
  link?: string;
  icon?: any;
  children?: IMenuItem[];
  permissionNum?: number;
  /**
   * If true, children will be rendered within a `MenuItem.ItemGroup`,
   * otherwise it'll use a `Menu.SubMenu`
   */
  group?: boolean;
  onClick?: () => void;
  visible?: boolean;
};

// use to hide based on env
// const isDevEnv = () => {
//   const devEnvs = ['development', 'local'];

//   return devEnvs.indexOf(process.env.NODE_ENV || '') > -1;
// };

const MenuItems: IMenuItem[] = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    route: '/',
    icon: <DashboardSVG className="sidebar-menu-icon" pathColor={iconColor} />,
  },
  {
    key: 'products-list',
    name: 'Product Information',
    route: '/products-list',
    icon: <TagOutlined className="sidebar-menu-icon" />,
  },
  {
    key: 'sell-old',
    name: 'Sell',
    route: '/sell',
    visible: false,
    icon: <Shopping pathColor={iconColor} />,
    children: [
      {
        key: 'sell-product-optimization-center',
        name: 'Product Optimization',
        route: '/sell/product-optimization-center',
      },
      {
        key: 'sell-product-bulk-update',
        name: 'Product Bulk Update',
        route: '/sell/product-bulk-update',
      },
    ],
  },
  {
    key: 'sell',
    name: 'Sell',
    route: '/sell',
    icon: <Shopping pathColor={iconColor} />,
    children: [
      /* {
        key: 'sell-product-bulk-update',
        name: 'Product Bulk Update',
        route: '/sell/product-bulk-update',
      }, */
      {
        key: 'sell-channel',
        name: 'Channel Listing',
        route: '/sell/channel/home',
      },
      {
        key: 'sell-settings-content-resources',
        name: 'Content Resources',
        route: '/sell/settings-content-resources',
      },
      {
        key: 'sell-images',
        name: 'Image Master List',
        route: '/sell/images',
      },
      {
        key: 'sell-product-optimization-center',
        name: 'Product Optimization',
        route: '/sell/product-optimization-center',
      },
      {
        key: 'sell-operation-sets',
        name: 'Operation Sets',
        route: '/sell/operation-sets',
      },
    ],
  },
  {
    key: 'fulfill',
    name: 'Fulfill',
    route: '',
    visible: false,
    // icon: <Fan pathColor={iconColor} />,
    icon: <CarryOutOutlined className="sidebar-menu-icon" />,
    children: [
      {
        key: 'fulfill-orders',
        name: 'Orders',
        route: '/channel-orders',
        permissionNum: 10001,
      },
      {
        key: 'fulfill-shipments',
        name: 'Shipments',
        route: '/fulfill-shipments',
        visible: false,
      },
      {
        key: 'fulfill-invoices',
        name: 'Invoices',
        route: '#',
        visible: false,
      },
      {
        key: 'fulfill-refund',
        name: 'Refund',
        route: '/fulfill-refund',
        visible: false,
      },
    ],
  },
  {
    key: 'selling-and-fulfillment',
    name: 'Fulfill',
    /*onClick: () => {
      linkERP.openSellDashboard();
    },*/
    // icon: <Fan height={24} pathColor={iconColor} />,
    icon: <CarryOutOutlined className="sidebar-menu-icon" />,
    children: [
      {
        key: 'selling-and-fulfillment-sales-orders',
        name: 'Sales Order',
        link: `${process.env.ERP_LINK}salesOrder/SalesOrderList`,
      },
      {
        key: 'selling-and-fulfillment-shipment',
        name: 'Shipment',
        link: `${process.env.ERP_LINK}shipment/shipmentList`,
      },
      {
        key: 'selling-and-fulfillment-pick-ticket',
        name: 'Pick Ticket',
        link: `${process.env.ERP_LINK}Fulfillments/FulfillmentsList`,
      },
      {
        key: 'selling-and-fulfillment-inventory',
        name: 'Inventory',
        link: `${process.env.ERP_LINK}Inventory/InventoryList`,
      },
      {
        key: 'fulfill-shipments',
        name: 'Shipments',
        route: '/fulfill-shipments',
        visible: false,
      },
      {
        key: 'fulfill-invoices',
        name: 'Invoices',
        route: '#',
        visible: false,
      },
      {
        key: 'fulfill-refund',
        name: 'Refund',
        route: '/fulfill-refund',
        visible: false,
      },
    ],
  },
  {
    key: 'buy-new',
    name: 'Buy',
    /*onClick: () => {
      linkERP.openBuyDashboard();
    },*/
    icon: <TagOutlined className="sidebar-menu-icon" />,
    children: [
      {
        key: 'buy-new-purchase-order',
        name: 'Purchase Order',
        link: `${process.env.ERP_LINK}purchaseOrder/purchaseOrderList`,
      },
      {
        key: 'buy-new-dropship',
        name: 'Dropship',
        link: `${process.env.ERP_LINK}Dropshipping/SellerOrderProcessingInquiry`,
      },
      {
        key: 'buy-new-vendor',
        name: 'Vendor',
        link: `${process.env.ERP_LINK}vendor/vendorList`,
      },
    ],
  },
  {
    key: 'customerService',
    name: 'Customer Service',
    //route: '/custom-service',
    icon: <CustomService pathColor={iconColor} />,
    children: [
      {
        key: 'customerService-customer',
        name: 'Customer',
        link: `${process.env.ERP_LINK}Customer/CustomerList`,
      },
      {
        key: 'customerService-track-order',
        name: 'Track Order',
        route: '/customerService/track-order',
      },
    ],
  },
  {
    key: 'channel-orders',
    name: 'Track',
    route: '/channel-orders',
    // icon: <Eyeglasses pathColor={iconColor} width={20} height={20} style={{ marginLeft: '-10px', marginTop: '5px' }} />,
    icon: <MonitorOutlined className="sidebar-menu-icon" />,
    visible: false,
  },
  {
    key: 'return',
    name: 'Return',
    /*onClick: () => {
      linkERP.openInvoiceCustomerReturnList();
    },*/
    icon: <RetweetOutlined className="sidebar-menu-icon" />,
    link: `${process.env.ERP_LINK}Return/InvoiceReturn`,
  },
  {
    key: 'warehouse',
    name: 'Warehouse',
    link: process.env.WMS_LINK,
    icon: <Warehouse className="sidebar-menu-icon" pathColor={iconColor} />,
  },
  {
    key: 'account-receivalbe',
    name: 'Accounts Receivable',
    icon: <AccountsReceivableIcon />,
    //icon: <AccountsReceivable className="sidebar-menu-icon" pathColor={iconColor} />,
    link: `${process.env.ERP_LINK}Invoice/InvoiceList`,
  },
  {
    key: 'accounts-payable',
    name: 'Accounts Payable',
    icon: <AccountsPayableIcon />,
    link: `${process.env.ERP_LINK}ApInvoice/ApInvoiceList`,
  },
  {
    key: 'business',
    name: 'Business Intelligence',
    icon: <RiseOutlined />,
    children: [
      {
        key: 'business-sales',
        name: 'Sales Analysis',
        /*onClick: () => {
          linkERP.openInquiryProductSalesLog();
        },*/
        link: `${process.env.ERP_LINK}Bi/ProductSalesInquiry`,
      },
      {
        key: 'business-financial',
        name: 'Financial Analysis',
        visible: false,
      },
      {
        key: 'business-inventory',
        name: 'Inventory Analysis',
        link: `${process.env.ERP_LINK}Bi/ProductSalesForecastingInquiry`,
      },
      {
        key: 'business-channel',
        name: 'Channel Audit',
        visible: false,
      },
    ],
  },
  
  {
    key: 'file',
    name: 'Import / Export',
    icon: <FileDoneOutlined />,
    children: [
      {
        key: 'file-import',
        name: 'Channel Import',
        route: '/file-import',
        icon: <Import pathColor={iconColor} className="sidebar-menu-icon" />,
      },
      {
        key: 'file-export',
        name: 'Channel Export',
        route: '/file-export',
        icon: <Export pathColor={iconColor} className="sidebar-menu-icon" />,
      },
    ],
  },
  {
    key: 'buy',
    name: 'Buy',
    route: '',
    icon: <TagOutlined />,
    visible: false,
    children: [
      {
        key: 'buy-customers',
        name: 'Customers',
        route: '/buy-customers',
      },
    ],
  },
  {
    key: 'import-old',
    name: 'Import',
    route: '/import-old',
    visible: false,
    icon: <Import pathColor={iconColor} />,
  },
  {
    key: 'export-old',
    name: 'Export',
    route: '/export-old',
    visible: false,
    icon: <Export pathColor={iconColor} />,
  },
  {
    //  An object with only a key and with divider included in key will be inserted as a divider
    key: 'divider-1',
  },
  {
    key: 'settings',
    name: 'Settings',
    icon: <Settings pathColor={iconColor} />,
    children: [
      {
        key: 'warehouse-old',
        name: 'Warehouse',
        route: '/warehouse',
        visible: false,
        icon: <Warehouse pathColor={iconColor} />,
        children: [
          {
            key: 'warehouse-distribution-centers',
            name: 'Distribution Centers',
            route: '/distribution-center',
          },
          {
            key: 'warehouse-order-routing-rules',
            name: 'Order Routing Rules',
            route: '/distribution-center-routing-rules',
          },
        ],
      },
      {
        key: 'shipping',
        name: 'Shipping',
        route: '',
        visible: false,
        icon: <Shipping pathColor={iconColor} />,
        children: [
          {
            key: 'shipping-carrier',
            name: 'Carriers',
            route: '/shipping/carrier',
            permissionNum: 10006,
          },
          {
            key: 'shipping-mapping',
            name: 'Channel Mapping',
            route: '/shipping/mapping',
            permissionNum: 10006,
          },
          {
            key: 'shipping-rules',
            name: 'Shipping Rules',
            route: '',
          },
        ],
      },
      {
        key: 'products',
        name: 'Products',
        route: '',
        icon: <OfferSVG pathColor={iconColor} />,
        permissionNum: Permissions.MANAGE_PRODUCT_ELEMENTS,
        children: [
          {
            key: 'settings-products-attributes',
            name: 'Channel Attributes',
            route: '/settings-products-attributes',
            permissionNum: Permissions.MANAGE_PRODUCT_ELEMENTS,
          },
          {
            key: 'settings-products-attribute-sets',
            name: 'Operation Sets',
            route: '/settings-products-attribute-sets',
            permissionNum: Permissions.MANAGE_PRODUCT_ELEMENTS,
          },
          {
            key: 'settings-products-copywriting',
            name: 'Content Resources',
            route: '/settings-products-copywriting',
            permissionNum: Permissions.MANAGE_PRODUCT_ELEMENTS,
          },
          /*{
            key: 'settings-products-classifications',
            name: 'Classifications',
            route: '/settings-products-classifications',
            permissionNum: Permissions.MANAGE_PRODUCT_ELEMENTS,
          },*/
          {
            key: 'settings-products-labels',
            name: 'Tags',
            route: '/settings-products-tags',
            permissionNum: Permissions.MANAGE_PRODUCT_ELEMENTS,
          },
          {
            key: 'settings-products-media-placement',
            name: 'Media Placement',
            route: '/settings-products-media-placement',
            permissionNum: Permissions.MANAGE_PRODUCT_ELEMENTS,
            visible: false,
          },
        ],
      },
      /*{
        key: 'customer-service',
        name: 'Customer Service',
        //route: '/custom-service',
        icon: <CustomService pathColor={iconColor} />,
        visible: false
      },*/
      {
        key: 'dropship',
        name: 'Dropship',
        route: '/drop-shipping',
        icon: <DropShippingIcon pathColor={iconColor} />,
        visible: false,
        children: [
          {
            key: 'dropship-seller-settings',
            name: 'Seller Config',
            route: '/dropship/seller-settings',
          },
          {
            key: 'dropship-supplier-settings',
            name: 'Supplier Config',
            route: '/dropship/supplier-settings',
          },
        ],
      },
      {
        key: 'channels',
        name: 'Channels',
        route: '',
        icon: <ApiOutlined />,
        permissionNum: Permissions.MANAGE_CHANNEL_ACCOUNT,
        children: [
          {
            key: 'settings-channels-integrations',
            name: 'Channel Integrations',
            route: '/settings-channels-integrations',
          },
          {
            key: 'settings-channels-control-flags',
            name: 'Channel Control Flags',
            route: '/settings-channels-control-flags',
          },
          {
            key: 'settings-dashboard',
            name: 'Dashboard',
            route: '/settings-dashboard',
          },
        ],
      },
      // {
      //   key: 'settings-output-template',
      //   name: 'Output Templates',
      //   route: '/settings-output-templates',
      //   icon: <ProfileOutlined />,
      //   permissionNum: Permissions.MANAGE_CHANNEL_ACCOUNT,
      // },
      {
        key: 'settings-distribution-centers',
        name: 'Distribution Centers',
        route: '/settings-distribution-center',
        icon: <Shipping className="sidebar-menu-icon" pathColor={iconColor} />,
      },
      {
        key: 'settings-profile-settings',
        name: 'Profile Settings',
        route: '/settings-profile-settings',
        icon: <SettingOutlined className="sidebar-menu-icon" />
      },
      {
        key: 'settings-summary',
        name: 'Summary',
        route: '/settings-summary',
        permissionNum: Permissions.MANAGE_CHANNEL_ACCOUNT,
        icon: <Kanban className="sidebar-menu-icon" pathColor={iconColor} width={20} height={20} style={{ marginTop: '5px' }} />,
      },
    ],
  },
  {
    key: 'administration',
    name: 'Administration',
    icon: <UserOutlined />,
    children: [
      {
        key: 'administration-user-management',
        name: 'Users',
        route: '/administration-user-management',
        icon: <Profile pathColor={iconColor} />,
        permissionNum: 10004,
      },
      {
        key: 'administration-roles',
        name: 'Roles',
        route: '/administration-roles',
        icon: <BulbOutlined />,
        permissionNum: 10004,
      },
    ],
  },
  {
    //  An object with only a key and with spacer included in key will be inserted as a spacer
    key: 'spacer-1',
  },
];

export default MenuItems;
