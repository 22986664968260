import {
  BranchesOutlined,
  CodepenOutlined,
  ContainerOutlined,
  ControlOutlined,
  DatabaseOutlined,
  NodeIndexOutlined,
  PictureOutlined,
  RiseOutlined,
  // ApiOutlined,
  // ProfileOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import React from 'react';
// import Export from '../../assets/icons/export';
// import Import from '../../assets/icons/import';
// import OfferSVG from '../../assets/icons/localOfferIcon';
import Settings from '../../assets/icons/settings';
// import Shipping from '../../assets/icons/shipping';
// import HomeSVG from '../../assets/icons/homeIcon';
import DashboardSVG from '../../assets/icons/dashboardMenu';
// import Fan from '../../assets/icons/fan';
// import Eyeglasses from '../../assets/icons/eye_glasses';
// import Kanban from '../../assets/icons/kanban';
import { Permissions } from '../../constants/enums/permissions';
//import { getStore } from '../../redux/store';
// import { isDevEnv } from '../../util';

const iconColor = 'currentColor';

export type IMenuItem = {
  key: string;
  name?: string;
  route?: string;
  /**
   * If the entry should be a link to an external page
   */
  link?: string;
  icon?: any;
  children?: IMenuItem[];
  permissionNum?: number;
  /**
   * If true, children will be rendered within a `MenuItem.ItemGroup`,
   * otherwise it'll use a `Menu.SubMenu`
   */
  group?: boolean;
  onClick?: () => void;
  visible?: boolean;
};

// use to hide based on env
// const isDevEnv = () => {
//   const devEnvs = ['development', 'local'];

//   return devEnvs.indexOf(process.env.NODE_ENV || '') > -1;
// };

const MenuItems: IMenuItem[] = [
  // {
  //   key: 'pim-home',
  //   name: 'Get Things Done',
  //   route: '/pim/home',
  //   icon: <HomeSVG pathColor={iconColor} />,
  // },
  {
    key: 'pim-dashboard',
    name: 'Dashboard',
    route: '/pim/dashboard',
    icon: <DashboardSVG className="sidebar-menu-icon" pathColor={iconColor} />,
  },
  {
    key: 'pim-products-list',
    name: 'Product Information',
    route: '/pim/products-list',
    icon: <CodepenOutlined className="sidebar-menu-icon" />,
  },
  {
    key: 'pim-content-source',
    name: 'Content Resources',
    route: '/pim/content-source',
    icon: <ContainerOutlined className="sidebar-menu-icon" />,
  },
  {
    key: 'pim-image-master-list',
    name: 'Image Master List',
    route: '/pim/image-master-list',
    icon: <PictureOutlined className="sidebar-menu-icon" />,
  },
  {
    key: 'pim-channel-list',
    name: 'Channel Listing',
    route: '/pim/channel-list/home',
    icon: <BranchesOutlined className="sidebar-menu-icon" />,
  },
  {
    key: 'pim-control-tower',
    name: 'Control Tower',
    route: '/pim/control-tower',
    icon: <ControlOutlined className="sidebar-menu-icon" />,
    permissionNum: Permissions.MANAGE_CONTROL_TOWER,
  },
  {
    key: 'pimDropshipping',
    name: 'My Network Dropshipping',
    icon: <NodeIndexOutlined className="sidebar-menu-icon" />,
    children: [
      {
        key: 'pimDropshipping-to-dropshipper',
        name: 'To Dropshipper',
        route: '/pimDropshipping-to-dropshipper',
      },
      {
        key: 'pimDropshipping-from-dropshipping-supplier',
        name: 'From Supplier',
        route: '/pimDropshipping-from-dropshipping-supplier',
        // visible: false
      },
    ]
  },
  {
    key: 'pim-product-optimization',
    name: 'Product Optimization',
    route: '/pim/product-optimization',
    icon: <RiseOutlined className="sidebar-menu-icon" />,
  },
  {
    key: 'pim-operation-sets',
    name: 'Operation Sets',
    route: '/pim/operation-sets',
    icon: <DatabaseOutlined className="sidebar-menu-icon" />,
  },
  {
    key: 'pimFile',
    name: 'Import / Export',
    icon: <UploadOutlined />,
    children: [
      {
        key: 'pimFile-import',
        name: 'Channel Import',
        route: '/pimFile-import',
        // icon: <Import pathColor={iconColor} />,
      },
      {
        key: 'pimFile-export',
        name: 'Channel Export',
        route: '/pimFile-export',
        // icon: <Export pathColor={iconColor} />,
      },
    ],
  },
  {
    //  An object with only a key and with divider included in key will be inserted as a divider
    key: 'divider-1',
  },
  {
    key: 'pimSettings',
    name: 'Settings',
    icon: <Settings pathColor={iconColor} />,
    children: [
      {
        key: 'products',
        name: 'Products',
        route: '',
        // icon: <OfferSVG pathColor={iconColor} />,
        permissionNum: Permissions.MANAGE_PRODUCT_ELEMENTS,
        children: [
          {
            key: 'pimSettings-products-attributes',
            name: 'Channel Attributes',
            route: '/pimSettings-products-attributes',
            permissionNum: Permissions.MANAGE_PRODUCT_ELEMENTS,
          },
          {
            key: 'pimSettings-products-attribute-sets',
            name: 'Operation Sets',
            route: '/pimSettings-products-attribute-sets',
            permissionNum: Permissions.MANAGE_PRODUCT_ELEMENTS,
          },
          {
            key: 'pimSettings-products-content-resources',
            name: 'Content Resources',
            route: '/pimSettings-products-content-resources',
            permissionNum: Permissions.MANAGE_PRODUCT_ELEMENTS,
          },
          {
            key: 'pimSettings-products-labels',
            name: 'Labels',
            route: '/pimSettings-products-labels',
            permissionNum: Permissions.MANAGE_PRODUCT_ELEMENTS,
          },
        ],
      },
      {
        key: 'inventory',
        name: 'Inventory',
        route: '',
        children: [
          {
            key: 'pimSettings-inventory-qty-overwrite-rule',
            name: 'Qty Overwrite Rule',
            route: '/pimSettings-inventory-qty-overwrite-rule',
          }
        ]
      },
      {
        key: 'channels',
        name: 'Channels',
        route: '',
        // icon: <ApiOutlined />,
        permissionNum: Permissions.MANAGE_CHANNEL_ACCOUNT,
        children: [
          {
            key: 'pimSettings-channels-integrations',
            name: 'Channel Integrations',
            route: '/pimSettings-channels-integrations',
          },
          {
            key: 'pimSettings-channels-control-flags',
            name: 'Channel Control Flags',
            route: '/pimSettings-channels-control-flags',
          },
          {
            key: 'pimSettings-dashboard',
            name: 'Dashboard',
            route: '/pimSettings-dashboard',
          },
        ],
      },
      {
        key: 'controlTower',
        name: 'Control Tower',
        route: '',
        // icon: <ApiOutlined />,
        permissionNum: Permissions.MANAGE_CONTROL_TOWER,
        children: [
          {
            key: 'pimSettings-controlTower-fields',
            name: 'Fields',
            route: '/pimSettings-controlTower-fields',
          },
          {
            key: 'pimSettings-controlTower-group',
            name: 'Group',
            route: '/pimSettings-controlTower-group',
          },
          {
            key: 'pimSettings-controlTower-layout',
            name: 'Layout',
            route: '/pimSettings-controlTower-layout',
          },
        ],
      },
      {
        key: 'pimSettings-output-template',
        name: 'Output Templates',
        route: '/pimSettings-output-templates',
        // icon: <ProfileOutlined />,
        permissionNum: Permissions.MANAGE_CHANNEL_ACCOUNT,
        visible: false,
      },
      {
        key: 'pimSettings-distribution-centers',
        name: 'Distribution Centers',
        route: '/pimSettings-distribution-centers',
        // icon: <Shipping pathColor={iconColor} />,
      },
      {
        key: 'pimSettings-file-management',
        name: 'File Management',
        route: '/pimSettings-file-management',
      },
      {
        key: 'pimSettings-profile-settings',
        name: 'Profile Settings',
        route: '/pimSettings-profile-settings',
      },
      {
        key: 'pimSettings-summary',
        name: 'Summary',
        route: '/pimSettings-summary',
        permissionNum: Permissions.MANAGE_CHANNEL_ACCOUNT,
        // visible: isDevEnv(),
        visible: false,
        // icon: <Kanban pathColor={iconColor} width={20} height={20} style={{ marginTop: '5px' }} />,
      },
      {
        key: 'enssentialInfo',
        name: 'Essential Info',
        route: '',
        // icon: <ApiOutlined />,
        // permissionNum: Permissions.MANAGE_CONTROL_TOWER,
        children: [
          {
            key: 'pimSettings-enssentialInfo-productFeature',
            name: 'Product Feature',
            route: '/pimSettings-enssentialInfo-productFeature',
          },
          {
            key: 'pimSettings-enssentialInfo-media',
            name: 'Media',
            route: '/pimSettings-enssentialInfo-media',
          },
        ],
      },
    ],
  },
  {
    //  An object with only a key and with spacer included in key will be inserted as a spacer
    key: 'spacer-1',
  },
];

export default MenuItems;
