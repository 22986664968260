import { Form, Input, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Permissions } from '../../constants/enums/permissions';
import FormButtons from '../common/FormButtons';

const { TextArea } = Input;

const TitleHeader = styled.label`
  width: 127px;
  height: 27px;
  font-family: Lato;
  font-size: 22px;
  font-weight: bold;
  color: #2d3f5d;
`;

interface LabelModalFormProps {
  submit: () => Promise<void>;
  handleCancel: () => void;
  formValues: Entities.ILabel;
  visible: boolean;
  handleChange: ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void) | undefined;
}

function ModalForm({ submit, formValues, visible, handleChange, handleCancel }: LabelModalFormProps) {
  const [processing, setProcessing] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleBeforeSubmit = async () => {

    try {
      setProcessing(true);
      await submit();
      form.resetFields();
    } catch (e) {
      // TODO: something
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Modal
      visible={visible}
      style={{ top: 30 }}
      title={(
        <TitleHeader>
          Label
        </TitleHeader>
      )}
      onCancel={handleCancel}
      closable={false}
      maskClosable={false}
      width={600}
      footer={(
        <FormButtons
          permissionNumber={Permissions.MANAGE_PRODUCT_ELEMENTS}
          onSave={form.submit}
          onCancel={handleCancel}
          editingMode
          style={{justifyContent: 'flex-end'}}
        />
      )}
    >
      <Spin spinning={processing}>
        <Form
          form={form}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ size: 'middle' }}
          onFinish={handleBeforeSubmit}
        >
          <Form.Item
            label="Tag Name"
            name="ProductLabelName"
            rules={[{ required: true, message: 'Tag name is required.' }]}
          >
            <Input
              onChange={handleChange}
              value={formValues.ProductLabelName}
              name="ProductLabelName"
              placeholder="Name"
              maxLength={50}
            />
          </Form.Item>

          <Form.Item
            name="ProductLabelDesc"
            label="Description"
          >
            <TextArea
              onChange={handleChange}
              value={formValues.ProductLabelDesc}
              name="ProductLabelDesc"
              placeholder="Description"
              maxLength={500}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default ModalForm;
