import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Spin, Card, Form, Select, Row, Button, Space, Typography, message, Modal, Input, Switch, Divider } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  // getAvailableCatagoryTemplates,
  getChannelAccountCustomSetting,
  getAllTemplates,
  // getChannelAccountStandardSetting,
  // getProductMappingList,
} from '../../../../services/channels';
import { useSelector } from 'react-redux';
import { IntegrationsState } from '../../../../redux/reducers/integrations';
import { FormsContext } from '../../details/context';
import { deleteTemplate, downloadTemplate } from '../../../../services/template';
import { getStandardTemplates, saveSyncTemplate } from '../../../../services/channels';
import UploadModal from '../../../Templates/UploadModal2';

const { Text } = Typography

type Props = {
  channelAccountNum: string;
  channelNum: string;
  platformNum: string;
};

const IntegrationTemplateTab = (props: Props) => {
  const { channelAccountNum, channelNum } = props;
  const form = useContext(FormsContext);
  const editMode = useSelector(
    ({ integrations }: { integrations: IntegrationsState }) => integrations.editMode,
  );
  const saveloading = useSelector(
    ({ integrations }: { integrations: IntegrationsState }) => integrations.loading,
  );
  const [loading, setLoading] = useState(false);
  const [inited, setInited] = useState(false);
  // const [templates, setTemplates] = useState<any[]>([]);
  const [customTemplates, setCustomTemplates] = useState<any[]>([]);
  const [assigned, setAssigned] = useState<any>();
  const [standardTemplates, setStandardTemplates] = useState<any[]>([]);
  const [uploadModalVisible, setUploadModalVisible] = useState<boolean>(false);

  const filenameFromHeader = (header: StringKAnyVPair) => {
    const str = header['content-disposition'];

    if (str) {
      const fields = str.split('=');

      return fields[fields.length - 1];
    }
  };

  const exportTemplate = async (productMappingNum: number, name: string) => {
    try {
      const res = await downloadTemplate(productMappingNum);
      // todo: save to file
      // console.log('res ->', res);
      const downloadName = filenameFromHeader(res.headers) || `Template-${name}.xlsx`;
      const content = res.data;
      const url = window.URL.createObjectURL(new Blob([content]));
      const link = document.createElement('a');
      link.href = url;
      //link.setAttribute('download', `Template-${name}.xlsx`);
      link.setAttribute('download', downloadName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      message.error(`Download file error: ${error}`);
    }
  };

  const delTemplate = async (name: string, mappingNum: number) => {
    try {
      Modal.confirm({
        title: 'Do you want to delete this template?',
        icon: <ExclamationCircleOutlined />,
        content: `Template Name: ${name}`,
        onOk: async () => {
          const res = await deleteTemplate(mappingNum);
          if (res) {
            message.success(res);
            getCustomTemplates();
          }
        },
        onCancel() {},
      });
    } catch (error) {}
  };

  const getTemplates = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getAllTemplates(channelAccountNum, channelNum);
      // const res1 = await getProductMappingList(channelAccountNum, channelNum);
      setLoading(false);
      const selected = res.templates.filter((i: any) => i.isGenerated).map((k: any) => k.contentStorageId);
        setTimeout(() => {
          form?.setFieldsValue({ 
            catalogSettingsIsForceUsing: res.isForceUsing,
            sourceCatalogSettings: { selectedTemplates: selected,  isForceUsing: res.isForceUsing }
          });
        }, 0);
    } catch (error) {
      setLoading(false);
    }
  }, [channelAccountNum, channelNum, form]);

  const getCustomTemplates = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getChannelAccountCustomSetting(channelAccountNum, channelNum);
      // const res = await getProductMappingList(channelAccountNum, channelNum);
      const temp = res.customTemplates.filter((i: any) => i.isAssignedFeedTemplate)
      setCustomTemplates(res.customTemplates);
      if(temp.length > 0){
        setAssigned(temp[0])
      } else {
        setAssigned(undefined)
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [channelAccountNum, channelNum]);

  const getSTemplates = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getStandardTemplates(channelAccountNum, channelNum);
      setStandardTemplates(res);
      setTimeout(() => {
        form?.setFieldsValue({
          standardTemplates: {
            selectedStandardTemplates: res
              .filter((i) => i.isGenerated)
              .map((j) => j.contentStorageId),
          },
          sourceStandardTemplates: {
            selectedStandardTemplates: res
              .filter((i) => i.isGenerated)
              .map((j) => j.contentStorageId),
          },
        });
      }, 0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [channelAccountNum, channelNum, form]);

  const assignedTemplate = useCallback(async(productMappingNum: number)=>{
    try {
      const res = await saveSyncTemplate(channelAccountNum, channelNum, productMappingNum)
      if(res){
        getCustomTemplates()
      }
    } catch (error) {
      console.log(error)     
    }
  },[channelAccountNum, channelNum, getCustomTemplates])

  useEffect(() => {
    if (!inited) {
      getCustomTemplates();
      getSTemplates();
      getTemplates()
      setInited(true);
    }
  }, [getCustomTemplates, getSTemplates, getTemplates, inited]);

  return !inited ? (
    <Spin></Spin>
  ) : (
    <Spin spinning={loading || saveloading}>
      <Card>
        <Card type='inner' title="Standard Template" >
        <Form.Item
          // label="Standard Templates"
          noStyle
          name={['standardTemplates', 'selectedStandardTemplates']}
          // rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select
            style={{ width: 300 }}
            mode="multiple"
            disabled={!editMode}
            showArrow
            onChange={(value) => {
              form?.setFieldsValue({ standardTemplates: { selectedStandardTemplates: value } });
            }}
          >
            {standardTemplates.map((i: any) => (
              <Select.Option key={i.contentStorageId} value={i.contentStorageId}>
                {i.templateName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item noStyle name={['sourceStandardTemplates', 'selectedStandardTemplates']}>
          <Input type="hidden" />
        </Form.Item>
        </Card>

        <Card type='inner' title="Custom Template" style={{marginTop: 16}} extra={ <Button onClick={() => setUploadModalVisible(true)}>Import Custom Template</Button>}>
        <Form.Item
          label="Force Using Channel Catalog for Channel Account Mapping"
          name={['catalogSettingsIsForceUsing']}
          labelCol={{xxl: 8,  xl: 10, lg:12, md: 14 }}
          valuePropName="checked"
          // rules={[{ required: true, message: 'This field is required' }]}
        >
          <Switch
            checkedChildren="on"
            unCheckedChildren="off"
            disabled={!editMode}
            onChange={(checked) => {
              // formChanged();
              form?.setFieldsValue({ catalogSettingsIsForceUsing: checked });
            }}
          />
        </Form.Item>
          {assigned && <Form.Item
          labelCol={{xxl: 8, xl: 10, lg:12, md: 14 }}
          label="Assigned Catalog Sync Templates"
        >
          <Text>{assigned.productMappingName}</Text>
        </Form.Item>}
        <Form.Item noStyle name={['sourceCatalogSettings', 'isForceUsing']}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item noStyle name={['sourceCatalogSettings', 'selectedTemplates']}>
          <Input type="hidden" />
        </Form.Item>
        <Divider />
        {customTemplates.map((i: any) => (
            <Row key={i.productMappingNum} style={{marginBottom: 8}}>
              <Space>
                <div style={{ width: 250 }}>
                <Text>{i.productMappingName}</Text>
                </div>
                <Button
                  type="link"
                  onClick={() => exportTemplate(i.productMappingNum, i.productMappingName)}
                >
                  Export Template
                </Button>
                <Button
                  type="link"
                  onClick={() => delTemplate(i.productMappingName, i.productMappingNum)}
                >
                  Delete
                </Button>
                {i.isAssignedFeedTemplate? <Text type="success">Assigned</Text> : <Button onClick={()=>{assignedTemplate(i.productMappingNum)}}>Assign as  Catalog Template</Button>}
              </Space>
            </Row>
          ))}
        <Row>
        </Row>
      {uploadModalVisible && (
        <UploadModal
          templateType={4}
          channelNum={Number(channelNum)}
          channelAccountNum={Number(channelAccountNum)}
          title="Upload Custom Mapping template"
          onCancel={() => setUploadModalVisible(false)}
          onOk={() => {
            setUploadModalVisible(false);
            getCustomTemplates();
          }}
        />
      )}
        </Card>
      </Card>
    </Spin>
  );
};

export default IntegrationTemplateTab;
