import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Spin, Card, Form, Select, Input } from 'antd';
import {
  getAllTemplates,
  // getProductMappingList,
} from '../../../../services/channels';
import { useSelector } from 'react-redux';
import { IntegrationsState } from '../../../../redux/reducers/integrations';
import { FormsContext } from '../../details/context';

type Props = {
  channelAccountNum: string;
  channelNum: string;
  platformNum: string;
};


const CatalogTab = (props: Props) => {
  const { channelAccountNum, channelNum } = props;
  const form = useContext(FormsContext);
  const editMode = useSelector(
    ({ integrations }: { integrations: IntegrationsState }) => integrations.editMode,
  );
  const saveloading = useSelector(
    ({ integrations }: { integrations: IntegrationsState }) => integrations.loading,
  );
  const [loading, setLoading] = useState(false);
  const [inited, setInited] = useState(false);
  const [templates, setTemplates] = useState<any[]>([]);
  // const [syncTemplates, setSyncTemplates] = useState<any[]>([]);
  // const [changed, setChanged] = useState(false);

  // const formChanged = useCallback(() => {
  //   if (!changed) {
  //     setChanged(true);
  //     actions.setFormDirtyStatus(true);
  //   }
  // }, [changed, actions]);

  const getTemplates = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getAllTemplates(channelAccountNum, channelNum);
      // const res1 = await getProductMappingList(channelAccountNum, channelNum);
      setLoading(false);
      setTemplates(res.templates);
      // setSyncTemplates(res1);
      // const temp = res1.filter((i:any)=> i.isAssignedFeedTemplate);
      // const seleted1 = temp.length > 0 ? temp[0].productMappingNum : undefined;
      const selected = res.templates.filter((i: any) => i.isGenerated).map((k: any) => k.contentStorageId);
      setTimeout(() => {
        form?.setFieldsValue({ 
          catalogSettingsSelectedTemplates: selected,
          sourceCatalogSettings: { selectedTemplates: selected,  isForceUsing: res.isForceUsing }
        });
      }, 0);
    } catch (error) {
      setLoading(false);
    }
  }, [channelAccountNum, channelNum, form]);


  useEffect(() => {
    if (!inited) {
      getTemplates();
      setInited(true);
    }
  }, [getTemplates, inited]);

  return !inited ? (
    <Spin></Spin>
  ) : (
    <Spin spinning={loading || saveloading}>
      <Card>
        <Form.Item
          label="Choose templates to create channel attributes"
          labelCol={{span: 24}}
          name={['catalogSettingsSelectedTemplates']}
          // rules={[{ required: true, message: 'This field is required' }]}
        >
          <Select
            style={{ width: 500 }}
            mode="multiple"
            disabled={!editMode}
            showArrow
            onChange={(value) => {
              // formChanged();
              form?.setFieldsValue({ catalogSettingsSelectedTemplates: value  });
            }}
          >
            {templates.map((i: any) => (
              <Select.Option key={i.contentStorageId} value={i.contentStorageId}>
                {i.templateName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
          label="Force Using Channel Catalog for Channel Account Mapping"
          name={['catalogSettings', 'isForceUsing']}
          valuePropName="checked"
          // rules={[{ required: true, message: 'This field is required' }]}
        >
          <Switch
            checkedChildren="on"
            unCheckedChildren="off"
            disabled={!editMode}
            onChange={(checked) => {
              // formChanged();
              form?.setFieldsValue({ catalogSettings: { isForceUsing: checked } });
            }}
          />
        </Form.Item> */}
        {/* <Form.Item
          label="Assigned Catalog Sync Templates"
          name={['catalogSettings','assignedFeedTemplate']}
        >
          <Select
            style={{ width: 300 }}
            disabled={!editMode}
            onChange={(value) => {
              form?.setFieldsValue({ catalogSettings: { assignedFeedTemplate: value } });
            }}
          >
            {
              syncTemplates.map((i:any)=> <Select.Option key={i.productMappingNum} value={i.productMappingNum}>{i.templateName}</Select.Option>)
            }

          </Select>
        </Form.Item> */}
        <Form.Item noStyle name={['sourceCatalogSettings', 'selectedTemplates']}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item noStyle name={['sourceCatalogSettings', 'isForceUsing']}>
          <Input type="hidden" />
        </Form.Item>
        {/* <Form.Item noStyle name={['sourceCatalogSettings', 'assignedFeedTemplate']}>
          <Input type="hidden" />
        </Form.Item> */}
      </Card>
    </Spin>
  );
};

export default CatalogTab;
