import React from 'react';
import { Row, Typography, Tooltip } from 'antd';
import {LinkOutlined} from  '@ant-design/icons';

type Props = {
  icon?: React.ReactNode;
  iconWidth?: number;
  onTextClick?: Function;
  text: React.ReactNode;
  textIsButton?: boolean;
  erpUrl?: string
};

const SpaceCell = (props: Props) => {
  const { iconWidth = 0, erpUrl } = props;
  const [styles, setStyles] = React.useState<StringKAnyVPair>({});

  const handleTextClick = () => {
    if (typeof props.onTextClick === 'function') {
      props.onTextClick();
    }
  };

  React.useEffect(() => {
    if (props.textIsButton) {
      setStyles({
        color: '#1976d2',
        cursor: 'pointer',
      });
    }
  }, [props]);

  return (
    <Row align="middle" justify="space-between">
      <Typography.Text
        ellipsis={{tooltip: props.text}}
        onClick={handleTextClick}
        style={{...styles, width: `calc(100% - ${iconWidth}px)`}}
      >
        {props.text}
      </Typography.Text>
      {
      erpUrl && 
        <Tooltip
          placement="top"
          title="Open in ERP"
          trigger={["hover", "click"]}
        >
          <LinkOutlined
            style={{
              color: "#138496",
            }}
            onClick={() => window.open(erpUrl, "_blank")}
          />
        </Tooltip>
      }
      {props.icon && <>
        {props.icon}
      </>}

    </Row>
  );
};

export default SpaceCell;
